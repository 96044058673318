import {isMobile, isTablet} from 'react-device-detect';
import { detect } from 'detect-browser';
import objectFitImages from 'object-fit-images';

const accessibility = {
  startupTasks: (setBrowser) => {  
    if (!isMobile && !isTablet && !window.keyboardSet) {
      accessibility.keyboardNavigation();
      accessibility.resetKBAccessibility();
    } 
    accessibility.browserDetect(setBrowser);
  },
  keyboardNavigation: (e) => {
    window.keyboardSet = true;
    accessibility.keyboardNavigation = null;
    document.addEventListener("keydown", accessibility.keyboardNavigation = (e) => {
      
      e = e || window.event;
      switch (e.keyCode) {
        default:
          let body = document.body;
          body.classList.add("kb-accessibility");
          break;
      }
    }, false);
  },
  resetKBAccessibility: () => {
    document.addEventListener("mousedown", accessibility.resetAccessibility = (e) => {
      let body = document.body;
      body.classList.remove("kb-accessibility")
    }, false);
  },
  browserDetect: (setBrowser) => {
    const browser = detect();
    let gatsbyEl = document.querySelector("#___gatsby");

    switch (browser.name) {
      case 'ie':
        gatsbyEl.classList.add("ie");
        objectFitImages();
        setBrowser('ie')
        break;
      case 'safari':
        gatsbyEl.classList.add("safari");
        setBrowser('safari')
        break;
      case 'ios':
        gatsbyEl.classList.add("safari");
        setBrowser('safari')
        break;
      case 'edge':
        gatsbyEl.classList.add("edge");
        setBrowser('edge')
        break;
      default:
    }
  }
}

export default accessibility;
