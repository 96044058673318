import React from 'react'
// @ts-ignore
import FooterLeftBottom from '../../svgs/footer-left-bottom.svg'
// @ts-ignore
import FooterLeftBottomAnimated from '../../svgs/footer-left-bottom-animated.svg'
// @ts-ignore
import FooterLeftTop from '../../svgs/footer-left-top.svg'
// @ts-ignore
import FooterLeftTopAnimated from '../../svgs/footer-left-top-animated.svg'
// @ts-ignore
import FooterRightBottom from '../../svgs/footer-right-bottom.svg'
// @ts-ignore
import FooterRightBottomAnimated from '../../svgs/footer-right-bottom-animated.svg'
// @ts-ignore
import FooterRightTop from '../../svgs/footer-right-top.svg'
// @ts-ignore
import FooterRightTopAnimated from '../../svgs/footer-right-top-animated.svg'

interface props {
  data: any
  browser: string
}

interface state {
  revealRibbons: Boolean
}

export default class FooterRibbons extends React.Component<props, state> {
  hasRevealed: Boolean
  footerScrollEventListener: Function
  footerRibbons: HTMLElement

  constructor(props) {
    super(props);
    this.state = {
      revealRibbons: false
    };

    this.hasRevealed = false;
  }

  componentDidMount = () => {
    this.scrollEvent();
  }

  componentWillUnmount = () => {
    // @ts-ignore
    window.removeEventListener('scroll', this.footerScrollEventListener);
  }

  scrollEvent = () => {
    let last_known_scroll_position = 0;
    let ticking = false;

    // Scroll event with throttling
    window.addEventListener('scroll', this.footerScrollEventListener = (e) => {
      if (!this.hasRevealed) {
        last_known_scroll_position = window.scrollY;

        if (!ticking) {
          window.requestAnimationFrame(() => {
            this.scrollReveal(last_known_scroll_position);
            ticking = false;
          });

          ticking = true;
        }
      }
    });    
  }

  scrollReveal = (scroll_pos: number): void => {
    // When scroll goes to footer + half of page, enable svg reveal
    const footerOffset = this.footerRibbons.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const ribbonOffset = (footerOffset.top + scrollTop) - (window.innerHeight * 0.6);
    const bottomScreenScrollPoint = scroll_pos + window.innerHeight;

    if ((ribbonOffset < bottomScreenScrollPoint) && !this.hasRevealed) {
      this.hasRevealed = true;
      this.setState({revealRibbons: true})
    }
  }

  render () {
    let data = this.props.data;
    
    return (
      <div 
        className={`footer__ribbons`}
        ref={ref => this.footerRibbons = ref}
      >
        {this.props.browser == 'ie' || this.props.browser == 'edge' ?
          <>
            <div className="footer__ribbons-left">
              <FooterLeftBottom className="footer__ribbons-left-svg bottom"/>
              <FooterLeftTop className="footer__ribbons-left-svg top"/>
            </div>
            <div className="footer__ribbons-right">
              <FooterRightBottom className="footer__ribbons-right-svg bottom"/>
              <FooterRightTop className="footer__ribbons-right-svg top"/>
            </div>
          </>
        :
          <>
          {this.state.revealRibbons && 
            <>
              <div className="footer__ribbons-left">
                <FooterLeftBottomAnimated className="footer__ribbons-left-svg bottom"/>
                <FooterLeftTopAnimated className="footer__ribbons-left-svg top"/>
              </div>
              <div className="footer__ribbons-right">
                <FooterRightBottomAnimated className="footer__ribbons-right-svg bottom"/>
                <FooterRightTopAnimated className="footer__ribbons-right-svg top"/>
              </div>
            </>
          }
          </>
        }
      </div>
    )
  }
}
