export const handleMenuOpen = openClose => ({ type: 'HANDLE_MENU_OPEN', openClose });
export const setBrowser = browser => ({ type: 'SET_BROWSER', browser });

export const clearAllSearch = () => ({ type: 'CLEAR_ALL_SEARCH' });
export const setActiveSubjectFilter = filter => ({ type: 'SET_ACTIVE_SUBJECT_FILTER', filter });
export const setActiveLevelFilter = filter => ({ type: 'SET_ACTIVE_LEVEL_FILTER', filter });
export const removeActiveSubjectFilter = filter => ({ type: 'REMOVE_ACTIVE_SUBJECT_FILTER', filter });
export const removeActiveLevelFilter = filter => ({ type: 'REMOVE_ACTIVE_LEVEL_FILTER', filter });

export const setAvailableLevels = availableLevels => ({ type: 'SET_AVAILABLE_LEVELS', availableLevels });
export const setAvailableSubjects = availableSubjects => ({ type: 'SET_AVAILABLE_SUBJECTS', availableSubjects });
export const setSearchTerm = searchTerm => ({ type: 'SET_SEARCH_TERM', searchTerm });
export const setSearchTermActive = searchTermActive => ({ type: 'SET_SEARCH_ACTIVE', searchTermActive });
export const setNumberOfResults = numberOfResults => ({ type: 'SET_NUMBER_OF_RESULTS', numberOfResults });

export const setLightboxURL = URL => ({type:'SET_LIGHTBOX_URL', URL});
export const setLightbox = onOff => ({type:'SET_LIGHTBOX', onOff});