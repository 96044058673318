import React from 'react'

interface props {
  data: any
}

interface state {
}

export default class FooterSubfooter extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    
    return (
      <div className="footer__sub">
        <div className="outer-container">
          <div className="footer__sub-left">
            <a className="footer__sub-links" href={data.footer.privacyPolicyUrl}>Privacy Policy</a>
            <a className="footer__sub-links" href={data.footer.termsConditionsUrl}>Disclaimer</a>
            <p className="footer__sub-copywrite">{data.footer.copywriteText}</p>
          </div>
          <div className="footer__sub-right">
            <a href="https://versa.agency" target="_blank" className="footer__sub-agency">Digital Agency - VERSA</a>
          </div>
        </div>
      </div>
    )
  }
}
