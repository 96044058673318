import React from 'react'
import hideOnScroll from 'hide-on-scroll';
import animateScrollTo from 'animated-scroll-to';
import AnimatedLink from './animatedLink';

interface Props {
  [x: string]: any
}

interface State {
  [x: string]: any
}

export class Navigation extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
    this.hideOnScroll();
  }

  hideOnScroll = (): void => {
    hideOnScroll({
      navbarSelector: 'body',
      hidingClass: 'hide-nav',
      pollingInterval: 600
    });
  }

  scrollUp = () => {
    if (window.location.pathname == '/') {
      animateScrollTo(0, {
        speed: 500
      })
    } 
    if (this.props.openMenu) {
      document.querySelector('.tl-edges').classList.remove("nav-open");
      this.props.dispatchCloseMenu()
    }
  }

  linkOutClick = () => {
    setTimeout(() => {
      this.props.dispatchCloseMenu()
    }, 100);
  }

  handleBugerClick = () => {
    if (this.props.openMenu) {
      document.querySelector('.tl-edges').classList.remove("nav-open");
      this.props.dispatchCloseMenu()
      // @ts-ignore
      setTimeout(() => document.querySelector('.nav-burger').focus(), 300);

    } else {
      document.querySelector('.tl-edges').classList.add("nav-open");
      this.props.dispatchOpenMenu()
      // @ts-ignore
      setTimeout(() => document.querySelector('.nav-burger').focus(), 300);
    }
  }

  renderNavLinks = (data, mobile) => {
    return data.topNavigationLinks.map((link, index) => {
      return (
        <li key={`navlink-${index}`}>
          {mobile ? 
            <AnimatedLink to={link.navigationLinkUrl} onClick={this.linkOutClick}>
              {link.navigationLinkTitle}
            </AnimatedLink>
          :
            <AnimatedLink to={link.navigationLinkUrl}>
              {link.navigationLinkTitle}
            </AnimatedLink>
          }
        </li>
      ) 

    })
  }

  
  render () {
    let props = this.props;
    let data = props.data;
    
    return (      
        <nav className={`nav` + ' ' + this.props.transitionStatus}>
          <div className="outer-container">
            <div className="nav-left">
              <AnimatedLink to={`/`} onClick={this.scrollUp}>
                <img 
                  src={data.nav.logoLeft.url} 
                  alt={data.nav.logoLeft.alt} 
                  className="nav-logo__left"
                />
                <img 
                  src={data.nav.logoRight.url} 
                  alt={data.nav.logoRight.alt} 
                  className="nav-logo__right"
                />
              </AnimatedLink>
            </div>
            <div className="nav-right">
              <button 
                className={props.openMenu ? "nav-burger active" : "nav-burger"}
                tabIndex={0}
                aria-expanded={props.openMenu ? 'true' : 'false'}
                aria-label={props.openMenu ? "button - close navigation menu" : "button - open navigation menu"} 
                role="button" 
                aria-controls="navigation"
                onClick={this.handleBugerClick}
              >
                <div className="nav-burger__bar-1"></div>
                <div className="nav-burger__bar-2"></div>
                <div className="nav-burger__bar-3"></div>
                <div className="nav-burger__text">{props.openMenu ? `Close` : `Menu`}</div>
              </button>
              <ul>
                {this.renderNavLinks(data.nav, false)}
              </ul>
            </div>
          </div>
          <div className={props.openMenu ? "nav-mobile active" : "nav-mobile"}>
            <ul>
              {this.renderNavLinks(data.nav, true)}
            </ul>
          </div>
        </nav>
    )
  }
}
