import React from 'react'
import FooterSubscription from './footer-subscription';
import FooterSubfooter from './footer-subfooter';
import FooterFeedback from './footer-feedback';
import FooterRibbons from './footer-ribbons';

interface Props {
  data: any
  transitionStatus: string
  [x: string]: any
}

interface State {
  [x: string]: any
}

export class Footer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {
    let data = this.props.data;

    return (
      <footer className={`footer` + ' ' + this.props.transitionStatus}>
        <FooterFeedback data={data}/>
        <FooterSubscription data={data}/>
        <FooterSubfooter data={data}/>
        <FooterRibbons data={data} browser={this.props.browser}/>
      </footer>
    )
  }
}