import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { parseHTML } from './../../helpers/parseHTML';
import isEmail from 'validator/lib/isEmail'
// @ts-ignore
import FooterMailIcon from '../../svgs/footer-mail.svg'
// @ts-ignore
import footerStateGovLogo from '../../../static/static-images/state-gov.png'
// @ts-ignore
import ArrowWhiteRight from '../../svgs/arrow-white-right.svg'

interface props {
  data: any
}

interface state {
  emailValidation: boolean
  emailValidationType: string
  emailValidationMsg: string
}

export default class FooterSubscription extends React.Component<props, state> {
  emailInput: HTMLInputElement;
  submitButton: HTMLButtonElement;

  constructor(props) {
    super(props);
    this.state = {
      emailValidation: false,
      emailValidationType: '',
      emailValidationMsg: ''
    };

  }

  componentDidMount = () => {
  }


  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = this.emailInput.value;
    const emailEl = this.emailInput;
    const submitEl = this.submitButton;
    if (this.checkEmailInput()) {
      emailEl.disabled = true;
      submitEl.disabled = true;
      
      // @ts-ignore
      const result = await addToMailchimp(email);

      if (result.result == 'error') {
        this.sendValidation('Something went wrong, refresh and try again shortly', 'error')
      } else {
        this.sendValidation('Success! You will start receiving alerts shortly!', 'success')
      }
    }
  }

  checkEmailInput = (): boolean => {
    const email = this.emailInput.value;
    if (email == '') {
      this.sendValidation("Please enter a valid email", 'error');
    } else if (!isEmail(email)) {
      this.sendValidation("That email doesn't look correct", 'error');
    } else {
      return true
    }
  }

  clearValidation = (): void => {
    this.setState({emailValidation: false})
  }

  sendValidation = (msg: string, type: string): void => {
    this.setState({
      emailValidation: true,
      emailValidationType: type,
      emailValidationMsg: msg
    })
  }


  render () {
    let data = this.props.data;
    
    return (
      <div className="footer__subscription">
        <div className="static-container">
          <img src={footerStateGovLogo} className="footer__subscription-logo"/>
          <div className="footer__subscription-left">
            <h3 className="footer__subscription-title">{data.footer.footerTitle}</h3>
          </div>
          <div className="footer__subscription-right">
            <p>{parseHTML(data.footer.creativeCommons)}</p>
          </div>
          {/* <div className="footer__subscription-right">
            <p className="footer__subscription-subtitle">{data.footer.footerSubtitle}</p>
            <div className="footer__subscription-form-blurb">
              <FooterMailIcon />
              <span className="footer__subscription-text">{data.footer.subscriptionText}</span>
            </div>
            <form 
              className="footer__subscription-form"     
              onSubmit={e => this.handleSubmit(e)}
            >
              <input 
                type="text" 
                className={`footer__subscription-input ${this.state.emailValidationType}`}
                placeholder={`Your email`}
                onBlur={this.checkEmailInput}
                onFocus={this.clearValidation}
                ref={emailInput => this.emailInput = emailInput}
              />
              <button 
                className="footer__subscription-submit"
                ref={submitButton => this.submitButton = submitButton}
              >
                Sign up <ArrowWhiteRight/>
              </button>
            </form>
            <span 
              className={
                `footer__subscription-val 
                ${this.state.emailValidation ? 'active' : ''}
                 ${this.state.emailValidationType}
                `
              }
            >
              {this.state.emailValidationMsg}
            </span>
          </div> */}
        </div>
      </div>
    )
  }
}
