import React from 'react';
import moment from 'moment-es6';
import { deviceType, browserName, osName } from 'react-device-detect';
// @ts-ignore
import FooterThumbIcon from '../../svgs/footer-thumb.svg';
// @ts-ignore
import ArrowRightRed from '../../svgs/arrow-right-dark-red.svg';
// @ts-ignore
import BrYellow from '../../svgs/br-yellow.svg';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';

interface props {
  data: any;
}

interface state {
  active: boolean;
  rating: number;
  feedbackType: string;
  numberValidation: boolean;
  emailValidation: boolean;
  textTopValidation: boolean;
  textBottomValidation: boolean;
  feedbackMaxLengthValidation: boolean;
  textTopValidationMsg: string;
  textBottomValidationMsg: string;
  submitting: boolean;
  submitted: boolean;
  hideLeft: boolean;
  leftText: string;
  email: string;
  yesNo: string;
  recaptchaValidationMsg: string;
  recaptchaValidation: boolean;
}

interface feedbackObj {
  [x: string]: any;
}

export default class FooterFeedback extends React.Component<props, state> {
  textTop: HTMLTextAreaElement;
  textBottom: HTMLTextAreaElement;
  feedbackText: HTMLTextAreaElement;
  emailInput: HTMLInputElement;
  recaptchaRef: React.RefObject<ReCAPTCHA>;

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      rating: 0,
      feedbackType: '',
      numberValidation: false,
      emailValidation: false,
      textTopValidation: false,
      textTopValidationMsg: 'Please fill section out',
      feedbackMaxLengthValidation: false,
      textBottomValidation: false,
      textBottomValidationMsg: 'Please fill section out',
      submitting: false,
      submitted: false,
      hideLeft: false,
      email: '',
      yesNo: '',
      leftText: 'Help us improve your experience',
      recaptchaValidationMsg: 'Please complete recpatcha',
      recaptchaValidation: false,
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount = () => { };

  handleButtons = (feedbackType: string, yesNo?: string): void => {
    switch (feedbackType) {
      case 'feedback':
        this.setState(
          {
            active: true,
            feedbackType: feedbackType,
            submitting: false,
            submitted: false,
            yesNo: yesNo,
            hideLeft: false,
            leftText: 'Help us improve your experience',
          },
          () => {
            this.expandContent();
          }
        );
        break;
      case 'bug-report':
        this.setState(
          {
            active: true,
            feedbackType: feedbackType,
            submitting: false,
            submitted: false,
            hideLeft: false,
            leftText: 'Help us improve your experience',
          },
          () => {
            this.expandContent();
          }
        );
        break;
      case 'close':
        this.expandContent();
        setTimeout(() => {
          this.setState({
            active: false,
            rating: 0,
            feedbackType: '',
          });
        }, 400);
        break;
      default:
        break;
    }
  };

  capitalizeFirstLetter = (string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const token = await this.recaptchaRef.current.getValue();
    const dateTime = moment().format('MMMM Do YYYY, h:mm:ss a');
    const URL = window.location.href;
    const path = window.location.pathname;
    const feedbackAPI =
      "https://ivvoh0ors6.execute-api.ap-southeast-2.amazonaws.com/default/det-learning-sequences-pdf-upload?feedback='true'";

    let feedbackObj: feedbackObj = {
      device: this.capitalizeFirstLetter(
        deviceType === 'browser' ? 'desktop' : deviceType
      ),
      browser: browserName,
      os: osName,
      URL: URL,
      path: path,
      dateTime: dateTime,
      type: this.state.feedbackType,
      recaptchaToken: token,
    };

    if (
      this.state.feedbackType == 'feedback' &&
      this.allFeedbackValidationCheck() &&
      this.feedbackLengthValidation(this.feedbackText.value)
    ) {
      this.setState({ submitting: true });
      feedbackObj.yesNo = this.state.yesNo;
      feedbackObj.rating = this.state.rating + ' out of 10';
      feedbackObj.text = (document.getElementById(
        'feedback-form__text'
      ) as HTMLTextAreaElement).value;

      try {
        await fetch(feedbackAPI, {
          method: 'POST',
          body: JSON.stringify(feedbackObj),
        });
      } catch (error) {
        console.log(error);
      }
      this.formSubmitted();
    } else if (
      this.state.feedbackType == 'bug-report' &&
      this.allBugValidationCheck()
    ) {
      this.setState({ submitting: true });
      feedbackObj.top = (document.getElementById(
        'feedback-form__text-top'
      ) as HTMLTextAreaElement).value;
      feedbackObj.bottom = (document.getElementById(
        'feedback-form__text-bottom'
      ) as HTMLTextAreaElement).value;

      try {
        await fetch(feedbackAPI, {
          method: 'POST',
          body: JSON.stringify(feedbackObj),
        });
      } catch (error) {
        console.log(error);
      }

      this.formSubmitted();
    }
  };

  numberButton = (e) => {
    e.preventDefault();
    // Set value of number
    let value = e.target.value;

    this.setState({
      rating: value,
      numberValidation: false,
    });

    // Remove any active classes on buttons
    let numberButtons = document.querySelectorAll('.feedback-form__number');

    for (let button = 0; button < numberButtons.length; button++) {
      numberButtons[button].classList.remove('active');
    }

    // Add active class on button
    e.target.classList.add('active');
  };

  numberValidation = (): boolean => {
    if (this.state.rating == 0) {
      this.setState({ numberValidation: true });
      return false;
    } else {
      this.setState({ numberValidation: false });
      return true;
    }
  };

  checkEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  emailValidation = (): boolean => {
    if (this.emailInput.value !== '') {
      if (this.checkEmail(this.emailInput.value)) {
        this.setState({ emailValidation: false });
        return true;
      } else {
        this.setState({ emailValidation: true });
        return false;
      }
    } else {
      this.setState({ emailValidation: false });
      return true;
    }
  };

  feedbackLengthValidation = (value): boolean => {
    if (value.length > 200) {
      this.setState({ feedbackMaxLengthValidation: true });
      return false;
    } else {
      this.setState({ feedbackMaxLengthValidation: false });
      return true;
    }
  };

  feedBackValidation = (el: string): void => {
    let recaptchaToken = this.recaptchaRef.current.getValue();
    if (!recaptchaToken.length) {
      this.setState({
        recaptchaValidation: true,
        recaptchaValidationMsg: 'Please complete recaptcha section out',
      });
    }
  };

  bugValidation = (el: string): void => {
    let textTopVal = this.textTop.value;
    let textBottomVal = this.textBottom.value;
    let recaptchaToken = this.recaptchaRef.current.getValue();

    switch (el) {
      case 'textTop':
        if (textTopVal == '') {
          this.setState({
            textTopValidation: true,
            textTopValidationMsg: 'Please fill section out',
          });
        } else if (textTopVal.length > 200) {
          this.setState({
            textTopValidation: true,
            textTopValidationMsg:
              'This field has a max length of 200 characters',
          });
        }
        break;
      case 'textBottom':
        if (textBottomVal == '') {
          this.setState({
            textBottomValidation: true,
            textBottomValidationMsg: 'Please fill section out',
          });
        } else if (textBottomVal.length > 200) {
          this.setState({
            textBottomValidation: true,
            textBottomValidationMsg:
              'This field has a max length of 200 characters',
          });
        }
        break;
      case 'recaptcha':
        if (!recaptchaToken.length) {
          this.setState({
            recaptchaValidation: true,
            recaptchaValidationMsg: 'Please complete recaptcha section out',
          });
        }
    }
  };

  allFeedbackValidationCheck = (): boolean => {
    let numberValidation = this.numberValidation();
    let recaptchaToken = this.recaptchaRef.current.getValue();
    this.feedBackValidation('recaptcha');
    if (numberValidation || recaptchaToken.length) {
      return true;
    } else {
      return false;
    }
  };

  allBugValidationCheck = (): boolean => {
    let textTopVal = this.textTop.value;
    let textBottomVal = this.textBottom.value;
    let recaptchaToken = this.recaptchaRef.current.getValue();

    this.bugValidation('textTop');
    this.bugValidation('textBottom');
    this.bugValidation('recaptcha');

    if (
      textTopVal == '' ||
      textBottomVal == '' ||
      textTopVal.length > 200 ||
      textBottomVal.length > 200 ||
      !recaptchaToken.length
    ) {
      return false;
    } else {
      return true;
    }
  };

  removeValidation = (el) => {
    switch (el) {
      case 'textTop':
        this.setState({ textTopValidation: false });
        break;
      case 'textBottom':
        this.setState({ textBottomValidation: false });
        break;
      case 'feedbackText':
        this.setState({ feedbackMaxLengthValidation: false });
        break;
      case 'recaptcha':
        this.setState({ recaptchaValidation: false });
        break;
    }
  };

  expandContent = () => {
    let feedbackContent: HTMLElement = document.querySelector(`.fbcontent`);
    let elHeight = feedbackContent.scrollHeight + 80;

    if (feedbackContent.classList.contains('active')) {
      feedbackContent.style.maxHeight = 0 + 'px';
      feedbackContent.classList.remove('active');
    } else {
      feedbackContent.style.maxHeight = elHeight + 'px';
      feedbackContent.classList.add('active');
    }
  };

  formSubmitted = () => {
    this.setState(
      {
        submitted: true,
        hideLeft: true,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              hideLeft: false,
              leftText: 'Thank you for your feedback',
            },
            () => {
              setTimeout(() => {
                this.handleButtons('close');
              }, 2000);
            }
          );
        }, 400);
      }
    );
  };

  render() {
    let data = this.props.data;

    return (
      <>
        <div className='footer__feedback'>
          <div className='static-container'>
            <p>
              If you have any feedback about this page, please email {' '}
              <a href="mailto:arc@education.vic.gov.au">arc@education.vic.gov.au</a>
            </p>
          </div>
        </div>
      </>
    );
  }
}
