import React from 'react'
import 'react-image-lightbox/style.css';
import '../../scss/main.scss'
import Lightbox from 'react-image-lightbox';
import { connect } from 'react-redux';
import { handleMenuOpen, setBrowser } from '../../state/actions';
import { Navigation } from '../global/navigation';
import { Footer } from '../footer/footer';
import accessibility from '../../helpers/accessibility';
import Breadcrumbs from '../global/breadcrumbs';
import { setLightbox, setLightboxURL } from './../../state/actions';


class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  componentDidMount = () => {
    document.querySelector('.tl-edges').classList.remove("nav-open");
    accessibility.startupTasks(this.props.setBrowser);
    setTimeout(() => this.wrapImages(), 300);
  }

  wrapImages = () => {
    let contentImages = document.querySelectorAll('.sequence-layout__right img');

    for (let i = 0; i < contentImages.length; i++) {
      const image = contentImages[i];
      let imageURL = image.getAttribute('src');
      image.addEventListener("click", () => {
        this.props.setLightboxURL(imageURL);
        this.props.setLightbox(true);
      });
    }
  }

  render() {
    return (
      <>
        <Navigation
          data={this.props.data}
          dispatchOpenMenu={this.props.openMenu}
          dispatchCloseMenu={this.props.closeMenu}
          openMenu={this.props.menuOpen}
          browser={this.props.browser}
          transitionStatus={this.props.transitionStatus}
        />
        {this.props.class !== 'homepage' ? 
          <Breadcrumbs
            pageTitle={this.props.pageTitle}
            parents={this.props.parents}
            transitionStatus={this.props.transitionStatus}
          />
        :''}
        {this.props.lightbox && (
          <Lightbox
            mainSrc={this.props.lightboxURL}
            onCloseRequest={() => this.props.setLightbox(false)}
            reactModalProps={{ shouldReturnFocusAfterClose: false }}
          />
        )}
        <main 
          className={this.props.class + ' ' + this.props.transitionStatus}
        >
          {this.props.children}
        </main>
        <Footer 
          transitionStatus={this.props.transitionStatus}
          data={this.props.data}
          browser={this.props.browser}
        />
      </>
    )
  }
}

// Set default props
Layout.defaultProps = {
  frame: true,
  data: false,
  class: '',
  pageTitle: '',
  parents: [],
  transitionStatus: ''
};

export default connect(
  state => ({ 
    menuOpen: state.menuOpen,
    browser: state.browser,
    lightbox: state.lightbox,
    lightboxURL: state.lightboxURL
  }),
  dispatch => ({ 
    openMenu: () => dispatch(handleMenuOpen(true)),
    closeMenu: () => dispatch(handleMenuOpen(false)),
    setBrowser: browser => dispatch(setBrowser(browser)),
    setLightbox: onOff => dispatch(setLightbox(onOff)),
    setLightboxURL: URL => dispatch(setLightboxURL(URL))
  }),
)(Layout);