import React from 'react'
// @ts-ignore
import HouseIcon from '../../svgs/breadcrumbs-house.svg'
import AnimatedLink from './animatedLink';

interface props {
  data: any
  pageTitle: string
  transitionStatus: string
  parents: Array<parent>
}

interface parent {
  url: string
  title: string
}

interface state {
}

export default class Breadcrumbs extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    
    return (
      <div className={`breadcrumbs` + ' ' + this.props.transitionStatus}>
        <div className="breadcrumbs__inner outer-container">
          <HouseIcon/>
          <AnimatedLink to={`/`} className={``}>
            Home
          </AnimatedLink>
          {this.props.parents.length > 0 ? 
            this.props.parents.map((parent,index) => {    
              return (
                <span key={'parent-' + index}>
                  <span className="breadcrumbs__divider">/</span>
                  <AnimatedLink to={`/${parent.url}`} className={``}>
                    {parent.title}
                  </AnimatedLink>
                </span>
              )
            })
          :''}
          <span className="breadcrumbs__divider">/</span>
          <span className="breadcrumbs__current">{this.props.pageTitle}</span>
        </div>
      </div>
    )
  }
}