import React from 'react'
import { domToReact } from 'html-react-parser';
import parse from 'html-react-parser';
import AnimatedLink from '../components/global/animatedLink';
import filterObject from 'filter-obj';
import ReactDOMServer from 'react-dom/server';

export function parseHTML (html) {
  // First pass cleans HTML
 

 
   
  let firstParse = ReactDOMServer.renderToStaticMarkup(parse(html, replaceHTML));
  // Add special anchor checking for second pass
  let wrappedAnchors = WrapRelativeAnchors(firstParse);
  // Add fraction syntax in
  let wrappedHtml = WrapFractions(wrappedAnchors);
  // Second parse

  return parse(wrappedHtml, replaceHTML)
}

const replaceHTML = {
  replace: domNode => {
    if (domNode.attribs) {

      // Clear the HTML of a lot of extra garbage styles that get added in the CMS
      let acceptableAttributes = ["class", "id", "width", "height", "href", "start", 'src', 'Source'];
      const newAttrs = filterObject(domNode.attribs, key => acceptableAttributes.includes(key));
      domNode.attribs = newAttrs;

     
      switch (domNode.name) {
        case 'a':
          // Relative links converter
          // Because we're doing mulitple passes - AnimatedLink gets confused
          // Before second parse wrappedAnchors adds this special attr to trigger this conversion
          if (domNode.attribs.secondParseAnchor) {
            return (
              <AnimatedLink to={domNode.attribs.href} className={`internal-link`}>
                {domNode.children[0].data}
              </AnimatedLink>
            )
          }
          break;

        case 'iframe':
          // let youtubeID = YouTubeGetID(domNode.attribs.src);
          // let thumbnail = `https://img.youtube.com/vi/${youtubeID}/0.jpg`;
          let iframeWrap = React.createElement('div', 
            {className: 'video-container'}, 
            React.createElement('iframe', domNode.attribs)
          )
          return iframeWrap

        case 'table':
          return (
            <div className="table-wrap">
              <table>
                {domToReact(domNode.children)}
              </table>
            </div>
          )

        case 'img':
          let src = '';
          if (!domNode.attribs.src.includes('?fit=clip&w=813')) {
            src = domNode.attribs.src + '?fit=clip&w=813';
          } else {
            src = domNode.attribs.src;
          }

          if(domNode.attribs.src.includes('hit_') || domNode.attribs.src.includes('assessment.svg')){
            return React.createElement(
              'img', 
              {
                className: `content-image ${domNode.attribs.src.includes('hit_)') ? 'hit' : ''}`,
                src: src,
                width: domNode.attribs.width,
                height: domNode.attribs.height
              } 
            );
          } else {
            return React.createElement(
              'img', 
              {
                className: `content-image `,
                src: src,
                width: domNode.attribs.width ? domNode.attribs.width : '250px',
                height: domNode.attribs.height,
                display: 'flex',
                justifyContent: 'flex-start'
              } 
            );
          }
       

        case 'script':
          return
      
        case 'b':
          if (domNode.children.length > 0)
          return <b style={{fontWeight:'bold'}}>{domNode.children[0].data}</b>
          break
        default:
          return domNode;
      }
    }
  }
};

const WrapFractions = (html) => {
  // Captures any number forward slash any number ie. 12/123
  // Must have a leading space and a trailing space to convert
  // Also detects anything within "" to avoid urls that have the above pattern
  const fractionsRegex = /("[^"]+")|([0-9][0-9]*\/[0-9][0-9]*)|([/][0-9][0-9]*\/[0-9][0-9]*)/g;

  return html.replace(fractionsRegex, function replace(match) { 
    
    // Return if within a string or mid-url
    if (match.substring(0,1) === '"') return match;
    if (match.substring(0,1) === '/') return match;

    // Get list of superscript/subscript numerals
    const superscriptChars = '⁰¹²³⁴⁵⁶⁷⁸⁹';
    const subscriptChars = '₀₁₂₃₄₅₆₇₈₉';

    // Split fraction on forward slash
    const matchChunks = match.split('/');
    const beforeSlashNumbers = matchChunks[0];
    const afterSlashNumbers = matchChunks[1];

    // Set new strings for conversion
    let convertedBefore = '';
    let convertedAfter = '';

    for (let i = 0; i < beforeSlashNumbers.length; i++) {
      // Run through each number
      const number = beforeSlashNumbers.charAt(i);
      // Convert number to int
      const parsedNumber = parseInt(number);
      // Substring matches exactly to above Chars
      const superscriptNumber = superscriptChars.substring(parsedNumber, parsedNumber + 1);
      // Add char to converted string
      convertedBefore = convertedBefore + superscriptNumber;
    }

    // Same as above for subscript numbers
    for (let i = 0; i < afterSlashNumbers.length; i++) {
      const number = afterSlashNumbers.charAt(i);
      const parsedNumber = parseInt(number);
      const subscriptNumber = subscriptChars.substring(parsedNumber, parsedNumber + 1);
      convertedAfter = convertedAfter + subscriptNumber;
    }

    // add numbers back together and wrap in functions tag
    let finalConversion = convertedBefore + '/' + convertedAfter;

    return '<span class="fraction">' + finalConversion + '</span>'; 
  });
}

const WrapRelativeAnchors = (html) => {
  const anchorRegex = /<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g;
  return html.replace(anchorRegex, function replace(match) { 
    let splitAnchor = match.split('href');
    return splitAnchor[0] + ' secondParseAnchor href' + splitAnchor[1]; 
  });
}